/**
 * Form validation
 */
$(document).ready(function(){ 
	if (!!$.prototype.validate) {
		$("#contactForm").validate();
	}
});




/**
 * Testimonial slider
 */
import Swiper, { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

jQuery(document).ready(function($) {

	if ( typeof Swiper !== "undefined" ){

		new Swiper('.swiper-testimonials', {
            modules: [Autoplay, Pagination],
			slidesPerView: "1", 
            loop: true,
            autoplay: {
                delay: 10000,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
		});

	}

});




/**
 * Glossary select nav
 */

$('.glossary-nav__select').bind('change', function () {  
    $("html, body").animate({scrollTop: $('#' + $(this).val()).offset().top-60}, "fast");
}); 
